import React from 'react';
import classNames from 'classnames';
import { Modal } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { flagOptions } from '../../constants/options';
import { topImageActions } from '../../slices/topImageSlice';
import ModalTitle from '../modalTitle';
import Button from '../../components/tcButton';
import ImagesReference from '../imagesReference';
import { toSlateState } from '../richTextEditor/utils/serializer';
import RichTextEditor from '../richTextEditor';
import './style.scss';
import VideoImageTagElement from '../videoImageTagElement';
import { convertImageFieldName } from '../../utils/fnUtil';

/* トップ画像参照モーダル */

const TopImageReferenceModal = () => {
  const dispatch = useDispatch();

  const { referenceModalVisible, referenceTopImage } = useSelector(
    state => state.topImage
  );

  const {
    accountInfo: {
      new_contents_flag,
      add_article_data: { video_upload_flag },
    },
  } = useSelector(state => state.account);

  const initialValues = {
    start_post_period_date: null,
    start_post_period_hour: null,
    start_post_period_minutes: null,
    end_post_period_date: null,
    end_post_period_hour: null,
    end_post_period_minutes: null,
    display_order: null,
    title: null,
    sub_title: null,
    slider: '0',
    notice_flag: '0',
    link: null,
    banner_image_url: null,
    text: '',
    image_url: Array(9).fill(''),
  };

  const {
    start_post_period,
    end_post_period,
    display_order,
    title,
    sub_title,
    slider,
    notice_flag,
    link,
    banner_image_url,
    text,
    richText,
    image_url,
  } = referenceTopImage || initialValues;

  const onBack = () => {
    dispatch(topImageActions.closeReferenceModal());
  };

  const dateToString = (date, format) => moment(date)?.format(format) || '';
  const rangeDateString = (from, to) => {
    const fromString = dateToString(from, 'YYYY/M/D HH:mm');
    const toString = dateToString(to, 'YYYY/M/D HH:mm');
    return `${fromString} ~ ${toString}`;
  };

  return (
    <Modal
      className={'top-image-modal tc-modal'}
      open={referenceModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={900}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content reference-top-image-modal-area">
        <ModalTitle title={'トップ画像参照'} />
        <div className="input-area">
          <div className="reference-area">
            <span className={'pre-label'}>掲載期間</span>
            <span className="font-sm">
              {rangeDateString(start_post_period, end_post_period)}
            </span>
          </div>
          <div className="reference-area">
            <span className={'pre-label'}>表示優先順</span>
            <span className="font-sm">{display_order ?? '-'}</span>
          </div>
          <div className="reference-area">
            <span className={'pre-label'}>タイトル</span>
            <span className="font-sm">{title} </span>
          </div>
          <div className="reference-area">
            <span className={'pre-label'}>サブタイトル</span>
            <span className="font-sm">{sub_title} </span>
          </div>
          <div className="check-area">
            <span className={'pre-label'}>スライダー</span>
            <span className="font-sm">
              {flagOptions?.filter(e => e.value === slider)?.[0]?.label}
            </span>
          </div>
          <div className="check-area">
            <span className={'pre-label'}>プッシュ通知配信</span>
            <span className="font-sm">
              {flagOptions?.filter(e => e.value === notice_flag)?.[0]?.label}
            </span>
          </div>
          <div className="reference-area">
            <span className={'pre-label'}>外部リンク</span>
            <span className="font-sm">{link}</span>
          </div>
          <div className="image-area">
            <span className={'pre-label'}>
              {convertImageFieldName(video_upload_flag, 'バナー画像')}
            </span>
            <div className="image-border">
              <VideoImageTagElement imageUrl={banner_image_url} />
            </div>
          </div>

          {new_contents_flag === 0 ? (
            <>
              <div
                className="reference-area"
                style={{ alignItems: 'flex-start' }}
              >
                <span className={'pre-label'} style={{ flexShrink: 0 }}>
                  本文
                </span>
                <div
                  className="font-sm"
                  style={{
                    width: 'calc(100% - 130px)',
                    boxSizing: 'border-box',
                    wordWrap: 'break-word',
                  }}
                >
                  {text}
                </div>
              </div>
              <div className="image-area">
                <label className="pre-label">画像</label>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '80%',
                  }}
                >
                  <ImagesReference
                    image_url={image_url}
                    from={0}
                    to={2}
                    width={180}
                    height={200}
                  />
                  <ImagesReference
                    image_url={image_url}
                    from={3}
                    to={5}
                    width={180}
                    height={200}
                  />
                  <ImagesReference
                    image_url={image_url}
                    from={6}
                    to={8}
                    width={180}
                    height={200}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className={classNames({ 'detail-area': true })}>
              <div className="text-area">
                <label className="pre-label">本文</label>
                <div className="text-area" style={{ flexGrow: 1 }}>
                  <span className="editor-detail">
                    <RichTextEditor
                      readOnly={true}
                      editorState={toSlateState(richText)}
                    />
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className="button-container">
            <Button
              text="閉じる"
              theme="white"
              onClick={() => dispatch(topImageActions.closeReferenceModal())}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TopImageReferenceModal;
