import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { notificationActions } from '../../slices/notificationSlice';
import ModalTitle from '../modalTitle';
import Button from '../../components/tcButton';
import ImagesReference from '../imagesReference';
import { toSlateState } from '../richTextEditor/utils/serializer';
import RichTextEditor from '../richTextEditor';
import './style.scss';
import { convertImageFieldName } from '../../utils/fnUtil';
import VideoImageTagElement from '../videoImageTagElement';
import {fileTypes} from "../../constants/options";

/* 記事参照モーダル */

const DocumentReferenceModal = () => {
  const dispatch = useDispatch();

  const { referenceModalVisible, referenceDocument } = useSelector(
    state => state.notification
  );

  const {
    accountInfo: {
      new_contents_flag,
      add_article_data: { video_upload_flag },
    },
  } = useSelector(state => state.account);

  const initialValues = {
    title: '',
    message: '',
    text: '',
    link: '',
    image_url: Array(10).fill(''),
  };

  const {
    title,
    message,
    text,
    link,
    image_url,
    rich_text,
    document_category,
    push_flag,
  } = referenceDocument || initialValues;

  const onBack = () => {
    dispatch(notificationActions.closeReferenceModal());
  };

  return (
    <Modal
      className="notification-modal tc-modal"
      open={referenceModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={900}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle title={'記事参照'} />
        <div className="input-area">
          <div className="reference-area">
            <span className={'pre-label'}>区分</span>
            <span className="font-sm">
              {fileTypes?.filter(e => e.value === push_flag)?.[0]?.label}
            </span>
          </div>
          <div className="reference-area">
            <span className={'pre-label'}>タイトル</span>
            <span className="font-sm">{title}</span>
          </div>
          <div className="reference-area">
            <span className={'pre-label'}>メッセージ</span>
            <span className="font-sm">{message}</span>
          </div>
          <div className="reference-area">
            <span className={'pre-label'}>外部リンク</span>
            <span className="font-sm">{link}</span>
          </div>

          {document_category === '0' &&
            (new_contents_flag === 0 ? (
              <>
                <div
                  className="reference-area"
                  style={{ alignItems: 'flex-start' }}
                >
                  <span className={'pre-label'} style={{ flexShrink: 0 }}>
                    本文
                  </span>
                  <div
                    className="font-sm"
                    style={{
                      width: 'calc(100% - 130px)',
                      boxSizing: 'border-box',
                      wordWrap: 'break-word',
                    }}
                  >
                    {text}
                  </div>
                </div>
                <div className="image-area" style={{ marginTop: 22 }}>
                  <label className="pre-label text-required">
                    {convertImageFieldName(video_upload_flag, 'バナー画像')}
                  </label>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '80%',
                    }}
                  >
                    <ImagesReference
                      image_url={image_url}
                      from={0}
                      to={4}
                      width={120}
                      height={120}
                    />
                    <ImagesReference
                      image_url={image_url}
                      from={5}
                      to={9}
                      width={120}
                      height={120}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="image-area">
                  <span className={'pre-label'}>
                    {convertImageFieldName(video_upload_flag, 'バナー画像')}
                  </span>
                  {image_url?.[0] && (
                    <div className="image-border">
                      <VideoImageTagElement imageUrl={image_url?.[0]} />
                    </div>
                  )}
                </div>
                <div className="text-area" style={{ display: 'flex', flexFlow: "column" }}>
                  <span className="pre-label editor-detail-title">本文</span>
                  <span className="editor-detail">
                    <RichTextEditor
                      readOnly={true}
                      editorState={toSlateState(
                        rich_text
                          ? rich_text
                              .split('-----separator-----')
                              .map((str, idx) => ({
                                sort_num: idx + 1,
                                dom: str,
                              }))
                          : []
                      )}
                    />
                  </span>
                </div>
              </>
            ))}
          <div className="button-container">
            <Button
              text="閉じる"
              theme="white"
              onClick={() =>
                dispatch(notificationActions.closeReferenceModal())
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DocumentReferenceModal;
