import React, { memo, useState } from 'react';
import { useFormik } from 'formik';
import { useUpdateEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { geopushActions, updateGeopush } from '../../slices/geopushInfoSlice';
import { globalActions } from '../../slices/globalSlice';
import { Modal } from 'antd';
import ModalTitle from '../modalTitle';
import TcButton from '../tcButton';
import Yup from '../../utils/yupUtil';
import moment from 'moment';
import './style.scss';
import MapElement from '../mapElement';
import DateTimeRangePicker from '../util/dateTimeRangePicker';
import { handlerFormikFieldChange } from '../../utils/fnUtil';

const GeopushModal = memo(() => {
  const dispatch = useDispatch();

  const { accountInfo, accountBrandList, accountShopList } = useSelector(
    state => state.account
  );

  const { geopushDetail, geopushModalVisible, initialLocation } = useSelector(
    state => state.geopushInfo
  );

  const [availableShops, setAvailableShops] = useState([]);
  const [showNotification, setShowNotification] = useState(false);

  const yupObject = {
    send_plan_date_from: Yup.string()
      .nullable()
      .test(
        'send_plan_date_from',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.send_plan_time_from_hh &&
          testContext.parent.send_plan_time_from_mm
      ),
    send_plan_date_to: Yup.string()
      .nullable()
      .test(
        'send_plan_date_to',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.send_plan_time_to_hh &&
          testContext.parent.send_plan_time_to_mm
      ),
    location_info_list: Yup.lazy(value => {
      // 配列が空、またはnull/undefinedならtrue（バリデーションスキップ）
      if (!value || value.length === 0) {
        return Yup.array().nullable(true);
      }
      // 配列に要素があれば、中のオブジェクトをバリデーションする
      return Yup.array().of(
        Yup.object({
          location_name: Yup.string().nullable().required(),
          latitude: Yup.string()
            .nullable()
            .required()
            .matches(
              /^-?(90(\.0+)?|[1-8]?[0-9](\.\d+)?)$/,
              '-90~90の範囲で小数点を含む数値を入力してください'
            )
            .test('range', '-90~90の範囲で入力してください', value => {
              if (value) {
                const num = parseFloat(value);
                return num >= -90 && num <= 90;
              }
              return true;
            }),
          longitude: Yup.string()
            .nullable()
            .required()
            .matches(
              /^-?(180(\.0+)?|1?[0-7]?[0-9](\.\d+)?)$/,
              '-180~180の範囲で小数点を含む数値を入力してください'
            )
            .test('range', '-180~180の範囲で入力してください', value => {
              if (value) {
                const num = parseFloat(value);
                return num >= -180 && num <= 180;
              }
              return true;
            }),
          radius: Yup.string()
            .nullable()
            .required()
            .matches(
              /^(?:[5-9][0-9]|[1-9][0-9]{2,3}|10000)$/,
              '50~10000の数値を入力してください'
            ),
        })
      );
    }),
  };

  const formik = useFormik({
    initialValues: {
      document_id: '',
      send_type: '1',
      send_plan_date_from: null,
      send_plan_time_from_hh: null,
      send_plan_time_from_mm: null,
      send_plan_date_to: null,
      send_plan_time_to_hh: null,
      send_plan_time_to_mm: null,
      location_info_list: [],
      shop_list: [],
    },
    validateOnMount: true,
    validationSchema: Yup.lazy(({ send_type }) =>
      send_type === '1' ? Yup.object(yupObject) : Yup.object()
    ),
    onSubmit: values => {
      const {
        document_id,
        send_type,
        send_plan_date_from,
        send_plan_time_from_hh,
        send_plan_time_from_mm,
        send_plan_date_to,
        send_plan_time_to_hh,
        send_plan_time_to_mm,
        location_info_list,
        ...rest
      } = values;

      if (send_type === '1') {
        const sendDateFrom = moment(send_plan_date_from)
          .set('h', send_plan_time_from_hh)
          .set('m', send_plan_time_from_mm);
        const sendDateTo = moment(send_plan_date_to)
          .set('h', send_plan_time_to_hh)
          .set('m', send_plan_time_to_mm);
        if (
          moment(sendDateFrom).isBefore(moment()) ||
          moment(sendDateTo).isBefore(moment())
        ) {
          dispatch(
            globalActions.showErrorModal({
              title: 'エラー',
              message: '配信日に過去日を設定することはできません',
            })
          );
          return;
        }
      }

      // shop_listを削除しないと登録できない
      delete rest['shop_list'];

      dispatch(
        updateGeopush({
          ...rest,
          document_id,
          send_type,
          send_date: moment(send_plan_date_from)?.format('YYYY/MM/DD'),
          send_time: '0000',
          send_plan_date_from: `${moment(send_plan_date_from)?.format(
            'YYYY/MM/DD'
          )} ${send_plan_time_from_hh}:${send_plan_time_from_mm}`,
          send_plan_date_to: `${moment(send_plan_date_to)?.format(
            'YYYY/MM/DD'
          )} ${send_plan_time_to_hh}:${send_plan_time_to_mm}`,
          geopush_flag: true,
          operator_code: accountInfo.staff_code,
          location_info_list:
            location_info_list?.map(info => ({
              // ガチャの仕様に合わせて一時的に退避したshop_codeを元に戻す
              ...info,
              shop_code: info.evacuate_shop_code
                ? info.evacuate_shop_code
                : info.shop_code,
            })) ?? [],
          isCreate: !geopushDetail?.reserve_id,
        })
      );
    },
  });

  const { shop_list, location_info_list } = formik.values;

  const onBack = () => {
    dispatch(geopushActions.resetGeopushModal());
    formik.resetForm();
  };

  // 選択済みのショップをaccountShopListから除外する処理
  const exclusionSelectedShops = () =>
    accountShopList.filter(
      shop =>
        location_info_list.length === 0 ||
        !location_info_list.some(
          info =>
            info.evacuate_shop_code === shop.shop_code ||
            info.shop_code === shop.shop_code
        )
    );

  // ショップをテーブルから削除した時の処理
  const deleteLocationInfoList = deletedShop => {
    const deleteShopCode =
      deletedShop.evacuate_shop_code || deletedShop.shop_code;
    if (!deleteShopCode) return true;

    const shop = accountShopList.find(
      shop => shop.shop_code === deleteShopCode
    );
    if (!shop) return;

    // 選択済みショップを更新
    const filteredSelectedShopList = shop_list.filter(
      selectedShop => selectedShop.shop_code !== deleteShopCode
    );
    handlerFormikFieldChange(formik, 'shop_list', filteredSelectedShopList);

    // 未選択ショップを更新
    setAvailableShops([...availableShops, shop]);
  };

  useUpdateEffect(
    () =>
      geopushDetail &&
      formik.setValues(geopushDetail).then(() => {
        formik.validateForm();
        setAvailableShops(
          accountShopList.filter(
            shop =>
              !geopushDetail?.location_info_list.some(
                info => info.evacuate_shop_code === shop.shop_code
              )
          )
        );
      }),
    [geopushDetail]
  );

  useUpdateEffect(() => {
    if (!geopushModalVisible) {
      formik.resetForm();
      formik.validateForm();
      setAvailableShops([]);
      setShowNotification(false);
    }
  }, [geopushModalVisible]);

  return (
    <Modal
      className="notification-modal tc-modal"
      centered
      footer={null}
      closable={false}
      width={1000}
      open={geopushModalVisible}
      destroyOnClose={true}
      onCancel={onBack}
    >
      <div className="tc-modal-content">
        <ModalTitle title={'配信設定'} tip={'*は必須項目です'} />
        <div className="input-area geo-push-input-area">
          <DateTimeRangePicker
            formik={formik}
            preLabel={'配信設定'}
            fromFieldName={'send_plan_date_from'}
            fromHourFieldName={'send_plan_time_from_hh'}
            fromMinuteFieldName={'send_plan_time_from_mm'}
            toFieldName={'send_plan_date_to'}
            toHourFieldName={'send_plan_time_to_hh'}
            toMinuteFieldName={'send_plan_time_to_mm'}
            isRequired
            width={'100%'}
          />

          <MapElement
            accountBrandList={accountBrandList}
            accountShopList={exclusionSelectedShops()}
            formik={formik}
            selectedShopList={shop_list}
            selectShopListFieldName={`shop_list`}
            shopLocations={formik?.values?.location_info_list}
            shopLocationFieldName={`location_info_list`}
            availableShops={availableShops}
            setAvailableShops={setAvailableShops}
            initialLocation={initialLocation}
            deleteLocationInfoList={deletedShop =>
              deleteLocationInfoList(deletedShop)
            }
            showNotification={showNotification}
            setShowNotification={setShowNotification}
          />

          <div className="button-container">
            <TcButton text="戻る" theme="white" onClick={onBack} />
            <TcButton
              text={'登録'}
              disabled={!formik.isValid}
              onClick={formik.handleSubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default GeopushModal;
