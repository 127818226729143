import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useFormik } from 'formik';
import {
  fetchCouponResultList,
  couponResultActions,
  fetchCouponResultCsvFavoriteItem,
  updateCouponResultCsvFavoriteItem,
  csvDownloadCouponResult,
} from '../../slices/couponResultSlice';
import { dateFormat, handlerFormikFieldChange } from '../../utils/fnUtil';
import {
  couponResultFavoriteItemOptions,
} from '../../constants/options';
import { couponResultColumns } from '../../constants/columns';
import Icons from '../../constants/Icons';
import PageTitle from '../../components/pageTitle';
import TcButton from '../../components/tcButton';
import TcTable from '../../components/tcTable';
import ColumnTitle from '../../components/columnTitle';
import TableTitle from '../../components/tableTitle';
import CsvDownloadModal from '../../components/modal/csvDownloadModal';
import SelectColumsModal from '../../components/modal/selectCloumsModal';
import TcCheckbox from '../../components/tcCheckbox';
import moment from 'moment';
import './style.scss';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';
import SelectBox from '../../components/util/selectBox';

const CouponResult = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const {
    accountInfo,
    accountBrandList: brandList,
    accountShopList: shopList,
  } = useSelector(state => state.account);

  const {
    searchParams,
    couponResultList,
    total,
    csvDownloadModalVisible,
    selectColumsVisible,
    favoriteItem,
    showFields,
  } = useSelector(state => state.couponResult);

  const { selectOptionList } = useSelector(state => state.name);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => {
      const { from_delivery_date, to_delivery_date, ...rest } = values;
      const params = {
        ...rest,
        from_delivery_date: dateFormat(from_delivery_date, 'YYYY/MM/DD'),
        to_delivery_date: dateFormat(to_delivery_date, 'YYYY/MM/DD'),
      };

      dispatch(couponResultActions.saveSearchParams(params));
    },
  });

  const {
    brands,
    csv_coupon_type,
    csv_brands,
    csv_shops,
    csv_from_delivery_date,
    csv_to_delivery_date,
  } = formik.values;

  const filterCsvShopList = shopList.filter(
    s =>
      csv_brands.filter(b => b).length === 0 ||
      csv_brands.includes(s.brand_code)
  );

  useEffect(() => {
    dispatch(fetchCouponResultCsvFavoriteItem());
    dispatch(couponResultActions.saveSearchParams(null));
    dispatch(
      couponResultActions.saveShowFields(
        columns.filter(
          c =>
            !couponResultColumns.filter(d => c.key === d.sortField)[0]
              .defaultHiddenFiled
        )
      )
    );
    return () => dispatch(couponResultActions.clear());
  }, [dispatch]);

  useUpdateEffect(
    () => dispatch(fetchCouponResultList(searchParams)),
    [searchParams]
  );

  const columns = [
    ...couponResultColumns.map(col => ({
      key: col.sortField,
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort =>
            dispatch(couponResultActions.saveSearchParams({ sort }))
          }
        />
      ),
      dataIndex: col.sortField,
      render: col.render,
      // render:
      //   col.sortField === 'open_count' && accountInfo.coupon_opend_count_mask
      //     ? () => '-'
      //     : null,
    })),
  ];

  const displayColumnOptions = [
    ...couponResultColumns.map(e => ({ label: e.title, value: e.sortField })),
  ];

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle
        title="クーポン単位実績一覧"
        icon={<Icons.IconCoupon className="coupon-icon" />}
      />
      <div className="search-form">
        <InputContainer parent={parent} max={1472}>
          <TextBox formik={formik} label="クーポンCD" fieldName="coupon_code" />
          <TextBox formik={formik} label="クーポン名" fieldName="coupon_name" />
          <SelectBox
            formik={formik}
            label="クーポン種別"
            fieldName="coupon_type"
            options={[
              { label: 'すべて', value: null },
              ...(selectOptionList?.filter(
                s => s.field_name === 'クーポン種別'
              )?.[0]?.select_option ?? []),
            ]}
          />
          <TextBox formik={formik} label="台紙CD" fieldName="couponcard_code" />
          <TextBox formik={formik} label="台紙名" fieldName="couponcard_name" />
          <SelectBox
            formik={formik}
            label="ブランド"
            fieldName="brands"
            mode="multiple"
            options={brandList}
            extraOnChange={() => formik.setFieldValue('shops', [])}
          />
          <SelectBox
            formik={formik}
            label="店舗"
            fieldName="shops"
            mode="multiple"
            options={shopList.filter(
              shop => brands.length === 0 || brands.includes(shop.brand_code)
            )}
          />
          <DateTimeRangePicker
            formik={formik}
            label="配信日"
            fromFieldName="from_delivery_date"
            toFieldName="to_delivery_date"
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
        </div>
      </div>
      <TableTitle
        searchCount={total}
        onClickCsvOutput={() => {
          handlerFormikFieldChange(
            formik,
            'csv_from_delivery_date',
            csv_from_delivery_date || moment().format('YYYY/M/1')
          );
          handlerFormikFieldChange(
            formik,
            'csv_to_delivery_date',
            csv_to_delivery_date || moment().format('YYYY/M/D')
          );
          dispatch(couponResultActions.openCsvDownloadModal());
        }}
        onClickDisplayChange={() =>
          dispatch(couponResultActions.openSelectColumsModal())
        }
      />
      <TcTable
        columns={columns.filter(e =>
          showFields.map(s => s.dataIndex).includes(e.dataIndex)
        )}
        dataSource={couponResultList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(couponResultActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />
      <CsvDownloadModal
        line={5}
        width={800}
        requiredText={null}
        modalVisible={csvDownloadModalVisible}
        options={couponResultFavoriteItemOptions}
        favoriteItem={favoriteItem}
        disabled={
          csv_coupon_type === '0' &&
          !(csv_from_delivery_date && csv_to_delivery_date)
        }
        searchArea={
          <>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {(selectOptionList?.filter(
                s => s.field_name === 'クーポン種別'
              )?.[0]?.select_option || [])?.map(v => (
                <TcCheckbox
                  label={`${v.label}クーポン`}
                  onChange={() =>
                    handlerFormikFieldChange(formik, 'csv_coupon_type', v.value)
                  }
                  checked={csv_coupon_type === v.value}
                  className="coupon-result-csv-modal-checkbox"
                  isRadio={true}
                />
              ))}
            </div>
            <div style={{ display: 'flex', marginTop: 10 }}>
              <SelectBox
                formik={formik}
                label={'ブランド'}
                fieldName={'csv_brands'}
                disabled={csv_coupon_type !== '1'}
                mode={'multiple'}
                isRequired={csv_coupon_type === '1'}
                options={[{ label: 'すべて', value: '' }, ...brandList]}
                width={300}
                customOnChange={(v, fieldName) => {
                  const isNoSelect = v.filter(value => value).length === 0;
                  const isLastEmpty = v[v.length - 1] === '';
                  const value =
                    isNoSelect || isLastEmpty ? [''] : v.filter(value => value);
                  handlerFormikFieldChange(formik, fieldName, value);
                  handlerFormikFieldChange(formik, 'csv_shops', ['']);
                }}
              />
              <SelectBox
                formik={formik}
                label={'店舗'}
                fieldName={'csv_shops'}
                disabled={csv_coupon_type !== '1'}
                mode={'multiple'}
                isRequired={csv_coupon_type === '1'}
                options={[{ label: 'すべて', value: '' }, ...filterCsvShopList]}
                width={300}
                customOnChange={(v, fieldName) => {
                  const isNoSelect = v.filter(value => value).length === 0;
                  const isLastEmpty = v[v.length - 1] === '';
                  const value =
                    isNoSelect || isLastEmpty ? [''] : v.filter(value => value);
                  handlerFormikFieldChange(formik, fieldName, value);
                }}
                style={{ marginLeft: 24 }}
              />
            </div>
            <div className="csv-modal-warning">
              ※ブランド/店舗で「すべて」を選択された場合、ダウンロードに時間がかかる場合があります。
            </div>
            <DateTimeRangePicker
              formik={formik}
              label={'配信日'}
              fromFieldName="csv_from_delivery_date"
              toFieldName="csv_to_delivery_date"
              isRequired
            />
          </>
        }
        onFavoriteItem={checkedList => {
          const param = {
            screen_type: '0',
            favorite: {},
          };
          couponResultFavoriteItemOptions
            .filter(item => item)
            .forEach(item => (param.favorite[item.value] = '0'));
          checkedList
            .filter(item => item)
            .forEach(item => (param.favorite[item] = '1'));
          dispatch(updateCouponResultCsvFavoriteItem(param));
        }}
        onClose={() => dispatch(couponResultActions.closeCsvDownloadModal())}
        onDownload={checkedList => {
          dispatch(
            csvDownloadCouponResult({
              coupon_type: csv_coupon_type,
              brands: csv_coupon_type !== '1' ? [] : csv_brands.filter(v => v),
              shops: csv_coupon_type !== '1' ? [] : csv_shops.filter(v => v),
              from_delivery_date:
                csv_coupon_type === '0'
                  ? dateFormat(csv_from_delivery_date, 'YYYY/MM/DD')
                  : null,
              to_delivery_date:
                csv_coupon_type === '0'
                  ? dateFormat(csv_to_delivery_date, 'YYYY/MM/DD')
                  : null,
              screen_type: '2',
              columns: checkedList,
            })
          );
        }}
      />
      <SelectColumsModal
        title="表示する項目を選択してください"
        modalVisible={selectColumsVisible}
        closeModal={() =>
          dispatch(couponResultActions.closeSelectColumsModal())
        }
        options={displayColumnOptions}
        onDisplay={checkedList => {
          dispatch(
            couponResultActions.saveShowFields(
              columns.filter(col =>
                checkedList.some(checkedCol => checkedCol === col.key)
              )
            )
          );
        }}
        chekedValues={couponResultColumns
          .filter(e => !e.defaultHiddenFiled)
          .map(e => e?.sortField)}
        initialize={false}
      />
    </div>
  );
};

export default CouponResult;
