import { memo } from 'react';
import { useFormik } from 'formik';
import { useDebounce, useUpdateEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchReferrerStaffDetail,
  staffManagementActions,
  updateStaff,
} from '../../slices/staffManagementSlice';
import {
  dateFormat,
  handlerFormikFieldChange,
  hashPassword,
} from '../../utils/fnUtil';
import { genderOptions3, staffAuthOptions } from '../../constants/options';
import { Modal } from 'antd';
import { globalActions } from '../../slices/globalSlice';
import moment from 'moment';
import ModalTitle from '../modalTitle';
import TcAvatar from '../tcAvatar';
import TcButton from '../tcButton';
import MenuCheckbox from '../tcCheckbox/menuCheckbox';
import TcRadio from '../tcCheckbox/tcRadio';
import Yup from '../../utils/yupUtil';
import './style.scss';
import SelectBox from '../util/selectBox';
import TextBox from '../util/textBox';
import PasswordBox from '../util/passwordBox';
import CustomNumberPicker from '../util/customNumberPicker';
import DatePickerWrapper from '../util/datePickerWrapper';

const StaffModal = memo(() => {
  const dispatch = useDispatch();

  const { accountBrandList, accountShopList, accountInfo } = useSelector(
    state => state.account
  );

  const { staffModalVisible, staffDetail, referrerStaff } = useSelector(
    state => state.staffManagement
  );

  const { selectOptionList } = useSelector(state => state.name);

  const formik = useFormik({
    initialValues: {
      shop_code: null,
      brand_code: null,
      staff_code: '',
      staff_name: '',
      staff_name_kana: '',
      mail_address: '',
      password: '',
      password_confirm: '',
      tel1: '',
      tel2: '',
      tel3: '',
      gender: '0',
      height: 155,
      comment: '',
      use_from_date: moment().format('YYYY/M/D'),
      use_to_date: '2999/12/31',
      referrer_login_id: '',
      staff_auth: null,
      top_flag: 1,
      content_flag: 1,
      top_image_flag: 1,
      top_content_flag: 1,
      notice_flag: 1,
      notice_list_flag: 1,
      notice_reserve_list_flag: 1,
      notice_geopush_list_flag: 1,
      coupon_flag: 1,
      coupon_list_flag: 1,
      coupon_result_flag: 1,
      coupon_user_result_flag: 1,
      stamp_flag: 1,
      stamp_card_flag: 1,
      stamp_qr_flag: 1,
      stamp_user_result_flag: 1,
      stamp_result_flag: 1,
      stamp_rally_flag: 1,
      stamp_rally_card_flag: 1,
      stamp_rally_user_result_flag: 1,
      stamp_rally_result_flag: 1,
      point_exchange_result_flag: 1,
      analysis_flag: 1,
      analysis_download_flag: 1,
      analysis_active_user_flag: 1,
      analysis_reaction_map_flag: 1,
      mst_flag: 1,
      staff_mst_flag: 1,
      shop_mst_flag: 1,
      brand_mst_flag: 1,
      user_mst_flag: 1,
      enquete_flag: 1,
      enquete_list_flag: 1,
      settings_flag: 1,
      category_flag: 1,
      template_flag: 1,
      gacha_flag: 1,
      gacha_list_flag: 1,
      gacha_applicant_list_flag: 1,
      lottery_flag: 1,
      lottery_list_flag: 1,
      lottery_applicant_list_flag: 1,
      line_flag: 1,
      line_richmenu_list_flag: 1,
      line_account_list_flag: 1,
      name_flag: 1,
      advent_calendar_flag: 1,
      advent_calendar_list_flag: 1,
      profile_image: '',
      isCreate: true,
    },
    validateOnMount: true,
    validationSchema: Yup.lazy(({ isCreate }) =>
      Yup.object({
        shop_code: Yup.string().nullable().selected(),
        brand_code: Yup.string().nullable().selected(),
        staff_code: Yup.string().required(),
        staff_name: Yup.string().required(),
        staff_name_kana: Yup.string().kana().required(),
        mail_address: Yup.string().required().max(254).mail().mail_local(),
        ...(isCreate && {
          password: Yup.string().required().min(6).password(),
          password_confirm: Yup.string().required().passwordConfirm(),
        }),
        use_from_date: Yup.string().required(),
        use_to_date: Yup.string().required(),
        tel1: Yup.string().tel1(),
        tel2: Yup.string().tel2(),
        tel3: Yup.string().tel3(),
        staff_auth: Yup.string().nullable().selected(),
      })
    ),

    onSubmit: values => {
      const {
        tel1,
        tel2,
        tel3,
        password,
        use_to_date,
        use_from_date,
        ...rest
      } = values;

      if (!moment(use_to_date).isAfter(moment(use_from_date))) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message: '利用開始日は利用終了日より前の日付を入力してください',
          })
        );
        return;
      }
      dispatch(
        updateStaff({
          ...rest,
          use_to_date: dateFormat(use_to_date, 'YYYY/MM/DD'),
          use_from_date: dateFormat(use_from_date, 'YYYY/MM/DD'),
          tel: {
            tel1,
            tel2,
            tel3,
          },
          password: hashPassword(password),
        })
      );
    },
  });

  const {
    values: { brand_code, referrer_login_id },
    setValues,
    setTouched,
    setFieldTouched,
  } = formik;

  useUpdateEffect(
    () =>
      staffDetail &&
      setValues({
        ...staffDetail,
        isCreate: false,
      })
        .then(() =>
          setTouched({
            shop_code: true,
            brand_code: true,
            staff_code: true,
            staff_name: true,
            staff_name_kana: true,
            mail_address: true,
            use_from_date: true,
            use_to_date: true,
            tel1: true,
            tel2: true,
            tel3: true,
            staff_auth: true,
          })
        )
        .then(() => formik.validateForm()),
    [staffDetail]
  );

  useUpdateEffect(() => {
    !staffModalVisible && formik.resetForm();
    formik.validateForm();
  }, [staffModalVisible]);

  useDebounce(
    () => {
      referrer_login_id
        ? dispatch(
          fetchReferrerStaffDetail({
            staff_code: referrer_login_id,
          })
        ).then(() => setFieldTouched('referrer_login_id', true))
        : dispatch(staffManagementActions.clearReferrerStaff());
    },
    500,
    [referrer_login_id, dispatch]
  );

  useUpdateEffect(() => {
    if (referrerStaff) {
      setValues({
        ...formik.values,
        // TOPページ
        top_flag: referrerStaff.top_flag,
        // コンテンツ
        content_flag: referrerStaff.content_flag,
        top_image_flag: referrerStaff.top_image_flag,
        top_content_flag: referrerStaff.top_content_flag,
        // プッシュ通知
        notice_flag: referrerStaff.notice_flag,
        notice_list_flag: referrerStaff.notice_list_flag,
        notice_reserve_list_flag: referrerStaff.notice_reserve_list_flag,
        notice_geopush_list_flag: referrerStaff.notice_geopush_list_flag,
        // クーポン
        coupon_flag: referrerStaff.coupon_flag,
        coupon_list_flag: referrerStaff.coupon_list_flag,
        coupon_result_flag: referrerStaff.coupon_result_flag,
        coupon_user_result_flag: referrerStaff.coupon_user_result_flag,
        // スタンプ
        stamp_flag: referrerStaff.stamp_flag,
        stamp_card_flag: referrerStaff.stamp_card_flag,
        stamp_qr_flag: referrerStaff.stamp_qr_flag,
        stamp_user_result_flag: referrerStaff.stamp_user_result_flag,
        stamp_result_flag: referrerStaff.stamp_result_flag,
        // スタンプラリー
        stamp_rally_flag: referrerStaff.stamp_rally_flag,
        stamp_rally_card_flag: referrerStaff.stamp_rally_card_flag,
        stamp_rally_user_result_flag: referrerStaff.stamp_rally_user_result_flag,
        stamp_rally_result_flag: referrerStaff.stamp_rally_result_flag,
        point_exchange_result_flag: referrerStaff.stamp_rally_result_flag,
        // 抽選
        lottery_flag: referrerStaff.lottery_flag,
        lottery_list_flag: referrerStaff.lottery_list_flag,
        lottery_applicant_list_flag: referrerStaff.lottery_applicant_list_flag,
        // 分析
        analysis_flag: referrerStaff.analysis_flag,
        analysis_download_flag: referrerStaff.analysis_download_flag,
        analysis_active_user_flag: referrerStaff.analysis_active_user_flag,
        analysis_reaction_map_flag: referrerStaff.analysis_reaction_map_flag,
        // マスタ
        mst_flag: referrerStaff.mst_flag,
        staff_mst_flag: referrerStaff.staff_mst_flag,
        shop_mst_flag: referrerStaff.shop_mst_flag,
        brand_mst_flag: referrerStaff.brand_mst_flag,
        user_mst_flag: referrerStaff.user_mst_flag,
        // アンケート
        enquete_flag: referrerStaff.enquete_flag,
        enquete_list_flag: referrerStaff.enquete_list_flag,
        // 設定
        settings_flag: referrerStaff.settings_flag,
        category_flag: referrerStaff.category_flag,
        template_flag: referrerStaff.template_flag,
        name_flag: referrerStaff.name_flag,
        // ガチャ
        gacha_flag: referrerStaff.gacha_flag,
        gacha_list_flag: referrerStaff.gacha_list_flag,
        gacha_applicant_list_flag: referrerStaff.gacha_applicant_list_flag,
        // LINE
        line_flag: referrerStaff.line_flag,
        line_richmenu_list_flag: referrerStaff.line_richmenu_list_flag,
        line_account_list_flag: referrerStaff.line_account_list_flag,
        // アドベントカレンダー
        advent_calendar_flag: referrerStaff.advent_calendar_flag,
        advent_calendar_list_flag: referrerStaff.advent_calendar_list_flag,
      });
      handlerFormikFieldChange(formik, 'staff_auth', referrerStaff.staff_auth);
    }
  }, [referrerStaff]);

  const onBack = () => {
    dispatch(staffManagementActions.resetStaffModal());
    formik.resetForm();
  };

  return (
    <Modal
      className="staff-modal tc-modal"
      centered
      footer={null}
      closable={false}
      width={1000}
      open={staffModalVisible}
      destroyOnClose={true}
      onCancel={onBack}
    >
      <div className="tc-modal-content">
        <ModalTitle
          title={`社員${staffDetail ? '編集' : '登録'}`}
          tip={'*は必須項目です'}
        />
        <div className="input-area">
          <h3>所属</h3>
          <SelectBox
            formik={formik}
            fieldName="brand_code"
            preLabel="ブランド"
            width={'calc(95% - 300px)'}
            options={accountBrandList}
            extraOnChange={() =>
              handlerFormikFieldChange(formik, 'shop_code', null)
            }
            isRequired
          />
          <SelectBox
            formik={formik}
            fieldName="shop_code"
            preLabel="店舗"
            width={'calc(95% - 300px)'}
            options={accountShopList.filter(
              shop => !brand_code || shop.brand_code === brand_code
            )}
            isRequired
          />
          <h3 className="input-title">社員情報</h3>
          <TextBox
            formik={formik}
            fieldName="staff_code"
            preLabel="社員CD"
            width={560}
            uneditable={!!staffDetail}
            isRequired
          />
          <TextBox
            formik={formik}
            fieldName="staff_name"
            preLabel="社員名"
            width={560}
            isRequired
          />
          <TextBox
            formik={formik}
            fieldName="staff_name_kana"
            preLabel="社員名(カナ)"
            width={560}
            isRequired
          />
          <TextBox
            formik={formik}
            fieldName="mail_address"
            preLabel="メールアドレス"
            width={'100%'}
            isRequired
          />
          {!staffDetail && (
            <>
              <PasswordBox
                formik={formik}
                fieldName="password"
                preLabel="パスワード"
                width={560}
                isRequired
              />
              <PasswordBox
                formik={formik}
                fieldName="password_confirm"
                preLabel="パスワード(再入力)"
                placeholder="パスワードを入力してください"
                width={560}
                isRequired
              />
            </>
          )}
          <span className="cus-input-line input-tel-fax">
            <TextBox
              formik={formik}
              fieldName="tel1"
              preLabel="電話番号"
              width={300}
              placeholder="03"
              suffix={'ー'}
            />
            <TextBox
              formik={formik}
              fieldName="tel2"
              width={200}
              suffix={'ー'}
              placeholder="0000"
            />
            <TextBox
              formik={formik}
              fieldName="tel3"
              width={200}
              placeholder="0000"
            />
          </span>
          <span className="cus-input-line input-checkbox">
            <TcRadio
              formik={formik}
              label={'性別'}
              fieldName="gender"
              width={220}
              options={genderOptions3}
              cancelable={false}
              isRadio={true}
            />
          </span>
          <CustomNumberPicker
            formik={formik}
            fieldName={'height'}
            preLabel={'身長'}
            width={300}
            from={100}
            to={250}
            suffix={'cm'}
          />
          <span className="cus-input-line input-textarea">
            <label className="cus-pre-label">{'コメント'}</label>
            <textarea
              placeholder="コメントを入力してください"
              spellCheck={false}
              autoComplete={'false'}
              value={formik.values.comment}
              onChange={e =>
                handlerFormikFieldChange(formik, 'comment', e.target.value)
              }
            />
          </span>
          <div style={{ display: 'flex' }}>
            <DatePickerWrapper
              formik={formik}
              fieldName={'use_from_date'}
              preLabel="利用開始日"
              maxWidth={300}
              isRequired
              flexEnd
            />
            <DatePickerWrapper
              formik={formik}
              fieldName={'use_to_date'}
              prefix={'利用終了日'}
              maxWidth={240}
              isRequired
              prefixWidth={130}
              style={{ marginLeft: 24 }}
              flexEnd
            />
          </div>
          <h3 className="auth-title input-title">
            権限
            <div>
              <span className="auth-tip">
                プログラム内で権限に応じた制御をしているメニューの場合、プログラムでの設定が優先されます。
              </span>
              <span className="auth-tip">
                詳細はユーザーマニュアルをご確認ください
              </span>
            </div>
          </h3>
          <TextBox
            formik={formik}
            fieldName={'referrer_login_id'}
            preLabel={'参照元社員'}
            width={560}
          />
          <SelectBox
            formik={formik}
            fieldName={'staff_auth'}
            preLabel={'権限'}
            width={560}
            disabled={!!referrerStaff}
            options={selectOptionList
              ?.filter(s => s.field_name === '権限')?.[0]
              ?.select_option?.filter(
                option => option.value >= accountInfo.staff_auth
              )}
            isRequired
          />
          <span className="cus-input-line input-menu">
            <span className="cus-pre-label">利用メニュー</span>
            <MenuCheckbox formik={formik} disabled={!!referrerStaff} />
          </span>
          <TcAvatar formik={formik} fieldName="profile_image" />
          <div className="button-container">
            <TcButton text="戻る" theme="white" onClick={onBack} />
            <TcButton
              text={staffDetail ? '更新' : '登録'}
              disabled={!formik.isValid}
              onClick={formik.handleSubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default StaffModal;
