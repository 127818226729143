import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import { Modal } from 'antd';
import ModalTitle from '../modalTitle';
import TcButton from '../tcButton';
import { gachaActions } from '../../slices/gachaSlice';
const GachaResultModal = () => {
  const dispatch = useDispatch();
  const { applicant } = useSelector(state => state.gacha);
  const onBack = () => dispatch(gachaActions.closeResultModal());

  const initialValues = {
    applicant_count: 0,
    location_gift_list: [],
  };

  const { applicant_count, location_gift_list, lottery_method, trigger } =
    applicant || initialValues;

  return (
    <Modal
      className={'gacha-result-modal tc-modal'}
      visible={!!applicant}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle title="ガチャ結果" />
        <div className="input-area">
          <div className={'applicant_count-field-area'}>
            <div className={'applicant_count-field-label'}>参加人数</div>
            <div
              className={'applicant_count-field-count'}
            >{`${applicant_count.toLocaleString()}人`}</div>
          </div>
          {location_gift_list?.map((gift, idx) => (
            <div
              className={'location_gift_list-area'}
              key={`location_gift_list-area-${idx}`}
            >
              {['2', '5'].includes(trigger) && (
                <>
                  {location_gift_list?.length > 1 && (
                    <div className={'location_gift_list-group_name'}>
                      {gift?.group_name}
                    </div>
                  )}
                  <div className={'location_name-field-area'}>
                    <div className={'location_name-field-label'}>対象店舗</div>
                    <div className={'location_name-field-name'}>
                      {gift?.locations?.map(
                        (shop, idx) =>
                          shop.location_name && (
                            <div
                              className={'location_name-text'}
                              key={`location_name-text-${idx}`}
                            >
                              {shop.location_name}
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </>
              )}
              <div className={'rank_list-area'}>
                {gift?.rank_list.map((list, i) => (
                  <div
                    className={'rank_list-raw-area'}
                    key={`rank_list-raw-area-${i}`}
                  >
                    <div>{`${
                      list?.rank !== '9' ? list?.rank + '等' : 'はずれ'
                    }`}</div>

                    {lottery_method === 0 ? (
                      <div>{`設定当選本数：${
                        list?.max_winner_count
                          ? list?.max_winner_count + '本'
                          : '-'
                      } `}</div>
                    ) : (
                      <div>{`設定当選確率：${
                        list?.winner_probability
                          ? list?.winner_probability + '%'
                          : '-'
                      }`}</div>
                    )}

                    <div>{`当選数：${
                      list?.winner_count ? list?.winner_count + '本' : '-'
                    }`}</div>

                    {lottery_method === 0 && (
                      <div>{`残り本数：${
                        list?.remaining_count
                          ? list?.remaining_count + '本'
                          : '-'
                      }`}</div>
                    )}

                    {list?.point !== null && (
                      <div>{`ポイント：${list?.point}pt`}</div>
                    )}
                    {list?.stamp_count !== null && (
                      <div>{`スタンプ：${list?.stamp_count}個`}</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="button-container">
            <TcButton text="閉じる" onClick={onBack} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GachaResultModal;
