import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { globalActions } from './globalSlice';
import axios from '../utils/axiosUtil';
import { downloadFile } from '../utils/fnUtil';
import moment from 'moment';
import {toSlateState} from "../components/richTextEditor/utils/serializer";

const initialNineGift = {
  rank: '9',
  coupon_code: null,
  stamp_count: null,
  point: null,
  max_winner_count: null,
  winner_probability: 0,
  is_checked: false,
}

const initialLocation = {
  shop_code: "",
  location_name: "",
  latitude: "",
  longitude: "",
  radius: "",
  edit_flag: true,
}

const initialLocationGiftList = {
  group_id: '',
  group_name: '店舗別設定',
  selected_shop_list: [],
  locations: [],
  loser_probability: '',
  gift_list: [
    {
    rank: '1',
    coupon_code: null,
    stamp_count: null,
    point: null,
    max_winner_count: null,
    winner_probability: null,
    is_checked: true,
    },
    {
      rank: '2',
      coupon_code: null,
      stamp_count: null,
      point: null,
      max_winner_count: null,
      winner_probability: null,
      is_checked: false,
    },
    {
      rank: '3',
      coupon_code: null,
      stamp_count: null,
      point: null,
      max_winner_count: null,
      winner_probability: null,
      is_checked: false,
    },
    {
      rank: '9',
      coupon_code: null,
      stamp_count: null,
      point: null,
      max_winner_count: null,
      winner_probability: 0,
      is_checked: true,
    }],
};

const initialValues = {
  gacha_code: null,
  gacha_name: null,
  trigger_temp: null,
  trigger: null,
  consecutive_startup_days: null,
  banner_image_url: null,
  gacha_content: null,
  gacha_rich_content: toSlateState([]),
  image_url: Array(10).fill(''),
  fixed_phrase: null,
  campaign_start_date: null,
  campaign_start_hour: null,
  campaign_start_minute: null,
  campaign_end_date: null,
  campaign_end_hour: null,
  campaign_end_minute: null,
  gacha_start_date: null,
  gacha_start_hour: null,
  gacha_start_minute: null,
  gacha_end_date: null,
  gacha_end_hour: null,
  gacha_end_minute: null,
  campaign_notice_flag: '1',
  gacha_notice_flag: '1',
  lottery_method: '0',
  focused_rank: null,
  loser_probability: '',
  check_in_rule: '1',
  location_gift_list: [
    {...initialLocationGiftList}
  ],
};

const initialSearchParams = {
  gacha_code: '',
  gacha_name: '',
  campaign_start_date: null,
  campaign_end_date: null,
  gacha_start_date: null,
  gacha_end_date: null,
  trigger: null,
  deleted_flg: 0,
  offset: 0,
  count: 10,
  sort: 'gacha_code_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  initialValues,
  initialLocation,
  initialLocationGiftList,
  initialNineGift,
  total: 0,
  editModalVisible: false,
  currentGacha: null,
  csvGacha: null,
  deleteGacha: null,
  referenceGacha: null,
  applicant: null,
  favoriteItem: [],
  couponList: [],
  templateList: [],
  maxNumber: 1,
  geoOffset: 0,
  activeStamp: null, //有効なスタンプをセットする
  mode: ""
};

export const fetchGachaTemplateList = createAsyncThunk(
  'fetchGachaTemplateList',
  async () => {
    const TemplateRes = await axios.get('/settings/template/list', {params: {template_type: 4}});
    return {
      templateList: (TemplateRes?.item ?? []).map(t => ({
        ...t,
        label: t.template_title,
        value: t.template_code,
        template_text: t.template_text,
      })),
    };
  }
);

export const fetchGachaCsvFavoriteItem = createAsyncThunk(
  'fetchGachaCsvFavoriteItem',
  async params => axios.get('csv/favorite_item/list', { params })
);

export const fetchGachaCsvFavoriteItemCreate = createAsyncThunk(
  'fetchGachaCsvFavoriteItemCreate',
  async (params, { dispatch }) => {
    const response = await axios.post('csv/favorite_item/create', params);

    if (response?.code === '0')
      dispatch(globalActions.showSingleModal('お気に入り登録が完了しました'));

    return response.params;
  }
);

export const fetchGachaCsvDownload = createAsyncThunk(
  'fetchGachaCsvDownload',
  async params => await axios.get('csv/download', { params })
);

export const fetchGachaList = createAsyncThunk(
  'fetchGachaList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('gacha/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));

    return result;
  }
);

export const fetchGachaDetail = createAsyncThunk(
  'fetchGachaDetail',
  async params =>
    await axios.get('gacha', {
      params: { gacha_code: params.gacha_code },
    })
);

export const fetchGachaCopy = createAsyncThunk(
  'fetchGachaCopy',
  async params =>
    await axios.get('gacha', {
      params: { gacha_code: params.gacha_code },
    })
);

export const fetchGachaReference = createAsyncThunk(
  'fetchGachaReference',
  async params =>
    await axios.get('gacha', {
      params: { gacha_code: params.gacha_code },
    })
);

export const fetchGachaResult = createAsyncThunk(
  'fetchGachaResult',
  async params =>
    await axios.get('gacha/reference', {
      params: { gacha_code: params.gacha_code },
    })
);

export const fetchGachaCreate = createAsyncThunk(
  'fetchGachaCreate',
  async (params, { dispatch }) => {
    await axios.post('gacha/create', { ...params });
    dispatch(globalActions.showSingleModal('登録が完了しました'));
  }
);

export const fetchGachaUpdate = createAsyncThunk(
  'fetchGachaUpdate',
  async (params, { dispatch }) => {
    await axios.post('gacha/update', { ...params });
    dispatch(globalActions.showSingleModal('更新が完了しました'));
  }
);

export const fetchGachaDelete = createAsyncThunk(
  'fetchGachaDelete',
  async params => await axios.post('gacha/delete', { ...params })
);

export const gachaSlice = createSlice({
  name: 'gachaSlice',
  initialState,
  reducers: {
    openEditModal: state => ({
      ...state,
      editModalVisible: true,
      maxNumber: 1,
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteGacha: payload,
    }),
    openCsvDownloadModal: (state, { payload }) => ({
      ...state,
      csvGacha: payload,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      currentGacha: null,
      mode: '',
      maxNumber: 1,
    }),
    closeCsvDownloadModal: state => ({
      ...state,
      csvGacha: null,
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteGacha: null,
    }),
    closeReferenceModal: state => ({
      ...state,
      referenceGacha: null,
    }),
    closeResultModal: state => ({
      ...state,
      applicant: null,
    }),
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        offset: payload,
      },
    }),
    saveGeoOffset: (state, { payload }) => ({
      ...state,
      geoOffset: payload,
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchGachaTemplateList.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [fetchGachaCsvFavoriteItem.fulfilled]: (state, { payload }) => ({
      ...state,
      favoriteItem: { ...payload?.item?.favorite },
    }),
    [fetchGachaCsvFavoriteItemCreate.fulfilled]: (state, { payload }) => ({
      ...state,
      favoriteItem: { ...payload?.favorite },
    }),
    [fetchGachaCsvDownload.fulfilled]: (_state, { payload }) =>
      typeof payload === 'string' &&
      downloadFile(
        payload,
        `ガチャ一覧_${moment().format('YYYYMMDDHHmmss')}.csv`
      ),
    [fetchGachaList.fulfilled]: (state, { payload }) => ({
      ...state,
      total: payload?.total_count ?? 0,
      gachaList: [
        ...payload?.item?.gacha_list.map(gacha => {
          const {
            campaign_start_date,
            campaign_end_date,
            gacha_start_date,
            gacha_end_date,
            gacha_code,
            gacha_name,
            trigger,
            status,
            create_at,
            deleted_flg,
          } = gacha;

          return {
            gacha_code: gacha_code,
            gacha_name: gacha_name,
            trigger: trigger,
            campaign_start_date: campaign_start_date,
            campaign_end_date: campaign_end_date,
            gacha_start_date: gacha_start_date,
            gacha_end_date: gacha_end_date,
            status: status,
            create_at: create_at,
            deleted_flg: deleted_flg,
          };
        }),
      ],
    }),
    [fetchGachaDetail.fulfilled]: (state, { payload }) => {
      const { deleted_flg, gacha_rich_content, location_gift_list, ...rest } =
        payload?.item;

      const newLocationGiftList = location_gift_list?.map(locationGift => {
        const { gift_list, locations } = locationGift;

        const updatedGiftList = gift_list?.map(v => ({
          ...v,
          is_checked:
            v?.winner_probability !== null || v?.max_winner_count !== null,
        }));

        if (!gift_list?.some(v => v.rank === '9')) {
          updatedGiftList.push({
            rank: '9',
            coupon_code: null,
            stamp_count: null,
            point: null,
            max_winner_count: null,
            winner_probability: 0,
            is_checked: false,
          });
        }

        return {
          ...locationGift,
          loser_probability: updatedGiftList
            .filter(v => v.rank != '9')
            .filter(v => v.is_checked)
            .reduce((acc, gift) => acc - (gift.winner_probability ?? 0), 100),
          selected_shop_list: [],
          gift_list: updatedGiftList,
          locations: locations.map(l => ({
            ...l,
            shop_code: null,
            edit_flag:
              !l.location_name ||
              [l.latitude, l.longitude, l.radius].includes(null),
          })),
        };
      });

      return {
        ...state,
        editModalVisible: true,
        currentGacha: {
          ...rest,

          gacha_rich_content: gacha_rich_content
            ? gacha_rich_content
                .split('-----separator-----')
                .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
            : [],
          location_gift_list: newLocationGiftList,
        },
      };
    },
    [fetchGachaCopy.fulfilled]: (state, { payload }) => {
      const {
        deleted_flg,
        gacha_rich_content,
        locations,
        gift_list,
        location_gift_list,
        ...rest
      } = payload?.item;

      const locationGiftList = location_gift_list?.map(locationGift => {

        if (!locationGift.gift_list?.some(l => l.rank === "9")) {
          locationGift.gift_list.push(initialNineGift)
        }

        return {
          ...locationGift,
          gift_list: locationGift.gift_list?.map(v => ({
            ...v,
            is_checked: v?.stamp_count !== null || v?.point !== null,
          })),
        };
      });

      // 既存データと初期データをマージして新規作成時と同じ形のデータを補完する
      const mergedGiftList = giftList => {
        const giftMap = new Map();

        initialLocationGiftList.gift_list.forEach(item =>
          giftMap.set(item.rank, item)
        );

        giftList?.forEach(item => giftMap.set(item.rank, item));

        return Array.from(giftMap.values());
      };

      const newLocationGiftList = locationGiftList?.map(locationGift => {
        const newGiftList = mergedGiftList(locationGift.gift_list);

        return {
          ...locationGift,
          loser_probability: newGiftList
            .filter(v => v.rank != '9')
            .filter(v => v.is_checked)
            .reduce((acc, gift) => acc - (gift.winner_probability ?? 0), 100),
          gift_list: newGiftList,
          selected_shop_list: [],
        };
      });

      return {
        ...state,
        editModalVisible: true,
        mode: 'copy',
        currentGacha: {
          ...rest,
          gacha_rich_content: gacha_rich_content
            ? gacha_rich_content
                .split('-----separator-----')
                .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
            : [],
          location_gift_list: newLocationGiftList,
        },
      };
    },
    [fetchGachaReference.fulfilled]: (state, { payload }) => {
      const { deleted_flg, gacha_rich_content, lottery_method, ...rest } =
        payload?.item;

      return {
        ...state,
        referenceGacha: {
          ...rest,
          gacha_rich_content: gacha_rich_content
            ? gacha_rich_content
                .split('-----separator-----')
                .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
            : [],
          lottery_method: lottery_method.toString(),
        },
      };
    },
    [fetchGachaResult.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        applicant: payload?.item?.response,
      };
    },
    [fetchGachaCreate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      searchParams: { ...state.searchParams },
      mode: '',
      currentGacha: null,
    }),
    [fetchGachaUpdate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      currentGacha: null,
      searchParams: { ...state.searchParams },
    }),
    [fetchGachaDelete.fulfilled]: state => ({
      ...state,
      deleteGacha: null,
      searchParams: { ...state.searchParams },
    }),
  },
});

export const gachaActions = gachaSlice.actions;

export default gachaSlice.reducer;
