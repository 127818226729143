import React, { useEffect, useRef } from 'react';
import PageTitle from '../../components/pageTitle';
import Icons from '../../constants/Icons';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import TcButton from '../../components/tcButton';
import { dateFormat, handlerFormikFieldChange } from '../../utils/fnUtil';
import ColumnTitle from '../../components/columnTitle';
import TcTable from '../../components/tcTable';
import TableTitle from '../../components/tableTitle';
import './style.scss';
import { useUpdateEffect } from 'react-use';
import {
  fetchStampRallyResultCsvDownload,
  fetchStampRallyResultCsvFavoriteItem,
  fetchStampRallyResultCsvFavoriteItemCreate,
  stampRallyResultActions,
  fetchStampRallyResultList,
} from '../../slices/stampRallyResultSlice';
import { stampRallyResultColumns } from '../../constants/columns';
import {
  csvDownloadTypes,
  csvFavoriteItemTypes,
  stampRallyResultFavoriteItemOptions,
} from '../../constants/options';
import CsvDownloadModal from '../../components/modal/csvDownloadModal';
import InputContainer from '../../components/inputContainer';
import SelectColumsModal from '../../components/modal/selectCloumsModal';
import moment from 'moment';
import TextBox from '../../components/util/textBox';
import SelectBox from '../../components/util/selectBox';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';
import Yup from "../../utils/yupUtil";

const StampRallyResult = () => {
  const dispatch = useDispatch();

  const parent = useRef(null);

  const {
    searchParams,
    total,
    csvModalVisible,
    selectColumsModalVisible,
    favoriteItem,
    stampRallyResultList,
    showFields,
  } = useSelector(state => state.stampRallyResult);

  const { selectOptionList } = useSelector(state => state.name);

  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: values =>
      dispatch(stampRallyResultActions.saveSearchParams(values)),
  });

  const yupObject = {
    from_create_at: Yup.string().nullable().required(),
    to_create_at: Yup.string().nullable().required(),
  };

  const csvFormik = useFormik({
    initialValues: {
      from_create_at: null,
      to_create_at: null,
      checkedList: [],
    },
    validationSchema: Yup.object(yupObject),
    onSubmit: values => {
      const { from_create_at, to_create_at, checkedList } = values;
      dispatch(
        fetchStampRallyResultCsvDownload({
          from_create_at: dateFormat(from_create_at, 'YYYY/MM/DD'),
          to_create_at: dateFormat(to_create_at, 'YYYY/MM/DD'),
          screen_type: csvDownloadTypes['stamp_rally_result'],
          columns: checkedList,
        })
      );
    },
  });

  const { csv_from_date, csv_to_date } = csvFormik.values;

  useUpdateEffect(
    () => dispatch(fetchStampRallyResultList(searchParams)),
    [searchParams]
  );

  const columns = [
    ...stampRallyResultColumns
      .filter(column => showFields.some(field => field === column.sortField))
      .map(col => ({
        key: col.sortField,
        title: (
          <ColumnTitle
            title={col.title}
            formik={formik}
            sortField={col.sortField}
            onClick={sort => {
              dispatch(stampRallyResultActions.saveSearchParams({ sort }));
            }}
          />
        ),
        dataIndex: col.sortField,
        render: col.render,
      })),
  ];

  const displayColumnOptions = [
    ...stampRallyResultColumns.map(v => ({
      label: v.title,
      value: v.sortField,
    })),
  ];

  useEffect(() => {
    dispatch(stampRallyResultActions.saveSearchParams(null));
    dispatch(
      fetchStampRallyResultCsvFavoriteItem({
        screen_type: csvFavoriteItemTypes['stamp_rally_result'],
      })
    );
    dispatch(
      stampRallyResultActions.saveShowFields(
        stampRallyResultColumns
          .filter(v => !v.defaultHiddenFiled)
          .map(v => v?.sortField)
      )
    );
    return () => dispatch(stampRallyResultActions.clear());
  }, [dispatch]);

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle title="押印実績明細一覧" icon={<Icons.IconStampRally />} isStampRally />
      <div className="search-form">
        <InputContainer parent={parent} max={1098}>
          <TextBox formik={formik} label="会員番号" fieldName="user_code" />
          <TextBox
            formik={formik}
            label="台紙CD"
            fieldName="stamp_rally_code"
          />
          <TextBox
            formik={formik}
            label="台紙名"
            fieldName="stamp_rally_name"
          />
          <SelectBox
            formik={formik}
            label={'トリガー'}
            options={
              selectOptionList?.filter(s => s.field_name === 'トリガー')?.[0]
                ?.select_option ?? []
            }
            fieldName={'trigger'}
            placaeHolder={'トリガーを選択してください'}
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
        </div>
      </div>

      <TableTitle
        searchCount={total}
        onClickDisplayChange={() =>
          dispatch(stampRallyResultActions.openSelectColumsModal())
        }
        onClickCsvOutput={() => {
          handlerFormikFieldChange(
            csvFormik,
            'csv_from_date',
            csv_from_date || moment().format('YYYY/MM/01')
          );
          handlerFormikFieldChange(
            csvFormik,
            'csv_to_date',
            csv_to_date || moment().format('YYYY/MM/DD')
          );
          dispatch(stampRallyResultActions.openCsvDownloadModal());
        }}
      />

      <TcTable
        columns={columns}
        dataSource={stampRallyResultList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(stampRallyResultActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />

      <CsvDownloadModal
        line={5}
        modalVisible={csvModalVisible}
        requiredText={null}
        options={stampRallyResultFavoriteItemOptions}
        favoriteItem={favoriteItem}
        width={1000}
        disabled={!csvFormik.isValid}
        searchArea={
          <DateTimeRangePicker
            label={'押印日'}
            formik={csvFormik}
            fromFieldName={'from_create_at'}
            toFieldName={'to_create_at'}
            isRequired
          />
        }
        onFavoriteItem={checkedList => {
          const initial = stampRallyResultFavoriteItemOptions.reduce(
            (acc, item) => ({
              ...acc,
              favorite: {
                ...acc.favorite,
                [item.value]: '0',
              },
            }),
            {
              screen_type: csvFavoriteItemTypes['stamp_rally_result'],
              favorite: {},
            }
          );
          const param = checkedList.reduce(
            (acc, item) => ({
              ...acc,
              favorite: {
                ...acc.favorite,
                [item]: '1',
              },
            }),
            initial
          );
          dispatch(fetchStampRallyResultCsvFavoriteItemCreate(param));
        }}
        onClose={() =>
          dispatch(stampRallyResultActions.closeCsvDownloadModal())
        }
        onDownload={checkedList => {
          csvFormik.setValues({
            ...csvFormik.values,
            checkedList: checkedList,
          });
          csvFormik.handleSubmit();
        }}
      />

      <SelectColumsModal
        modalTitle="表示切替"
        title="表示する項目を選択してください"
        modalVisible={selectColumsModalVisible}
        closeModal={() =>
          dispatch(stampRallyResultActions.closeSelectColumsModal())
        }
        options={displayColumnOptions}
        onDisplay={checkedList =>
          dispatch(stampRallyResultActions.saveShowFields(checkedList))
        }
        chekedValues={stampRallyResultColumns
          .filter(v => !v.defaultHiddenFiled)
          .map(v => v?.sortField)}
        initialize={false}
      />
    </div>
  );
};

export default StampRallyResult;
