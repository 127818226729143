import './style.scss';
import DualListBox from '../dualListBox';
import { handlerFormikFieldChange } from '../../utils/fnUtil';
import TcButton from '../tcButton';
import React, { useState } from 'react';
import { mapLocationInfoColumns } from '../../constants/columns';
import ColumnTitle from '../columnTitle';
import TcTable from '../tcTable';
import TextBox from '../util/textBox';
import Icons from '../../constants/Icons';
import GeoMap from '../googleMap/geoMap';
import { Tooltip } from 'antd';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { globalActions } from '../../slices/globalSlice';

/**
 * ガチャとジオプッシュで使用
 */
const MapElement = ({
  accountBrandList, // セレクトボックスのブランド一覧
  accountShopList, // ブランドに紐づく店舗一覧
  formik,
  selectedShopList, // 選択したショップ一覧
  selectShopListFieldName, // 選択したショップ一覧を更新するためのフィールドネーム
  shopLocations, // 選択したショップの位置情報一覧
  shopLocationFieldName, // 選択したショップの位置情報一覧を更新するためのフィールドネーム
  availableShops, // まだ選択されていないショップ一覧
  setAvailableShops, // 選択していないショップ一覧を更新する関数
  initialLocation, // ローケーション情報の初期値
  deleteLocationInfoList, // テーブルの削除を行った時の処理(ジオプッシュ専用)
  showNotification, //警告モーダル表示したか
  setShowNotification, //警告モーダル表示関数
}) => {
  const dispatch = useDispatch();
  // マップを中央に配置するための処理
  const [center, setCenter] = useState({ lat: 35.69575, lng: 139.77521 });

  /**
   * 選択したショップを反映ボタンでテーブルに反映させる処理
   */
  const onclickDecision = () => {
    const selectedShopCodes = new Set(selectedShopList.map(s => s.shop_code));

    // selectedShopList から新規追加するロケーション情報を作成
    const newShopLocations = selectedShopList
      .filter(
        ({ shop_code }) =>
          !shopLocations.some(loc => loc.shop_code === shop_code)
      )
      .map(({ shop_code, label = '', location = [], radius = null }) => {
        const [latitude = null, longitude = null] = location;
        const editFlag = !label || [latitude, longitude, radius].includes(null);

        return {
          shop_code,
          location_name: label,
          latitude,
          longitude,
          radius,
          edit_flag: editFlag,
        };
      });

    // shopLocations + newShopLocations から、selectedShopList に含まれる shop_code のみを抽出
    const newLocationList = [...shopLocations, ...newShopLocations].filter(
      ({ shop_code }) => !shop_code || selectedShopCodes.has(shop_code)
    );

    handlerFormikFieldChange(formik, shopLocationFieldName, newLocationList);
  };

  // テーブルカラム
  const columns = [
    ...mapLocationInfoColumns.map(col => ({
      title: <ColumnTitle title={col.title} formik={formik} />,
      dataIndex: col.sortField,
      width: col.width,
      className: 'map-elements-columns',
      render: (value, record, index) => {
        return record.edit_flag ? (
          <TextBox
            formik={formik}
            placeholder={col.placeHolder}
            fieldName={`${shopLocationFieldName}[${index}].${col.sortField}`}
            suffix={col.sortField === 'radius' ? 'm' : ''}
            width={'none'}
          />
        ) : col.sortField === 'radius' ? (
          Number(value).toLocaleString() + 'm'
        ) : (
          value
        );
      },
    })),
    {
      title: '中央に表示',
      align: 'center',
      width: '140px',
      onCell: current => {
        return { colSpan: current.edit_flag ? 2 : 1 };
      },
      render: (current, value, index) => {
        return current.edit_flag ? (
          <TcButton
            text={'反映'}
            width="400"
            onClick={() => {
              if (!showNotification && Number(value?.radius) < 100) {
                setShowNotification(!showNotification);
                dispatch(
                  globalActions.showErrorModal({
                    title: '警告',
                    message:
                      '設定可能ではありますが推奨される最小範囲は100mです',
                  })
                );
              }

              let newAry = structuredClone(shopLocations);
              newAry[index].edit_flag = false;
              handlerFormikFieldChange(formik, shopLocationFieldName, newAry);
            }}
            disabled={
              !!get(formik.errors, `${shopLocationFieldName}[${index}]`)
            }
          />
        ) : (
          <TcButton
            text="中央に表示"
            theme="white"
            style={{
              width: 100,
            }}
            icon={<Icons.IconCheckIn className="btn-icon" />}
            onClick={() => {
              const shopLocation = shopLocations[index];
              // 中央表示の初期値を更新
              setCenter({
                lat: Number(shopLocation.latitude),
                lng: Number(shopLocation.longitude),
              });
            }}
          />
        );
      },
    },
    {
      title: '編集',
      align: 'center',
      width: '80px',
      onCell: current => {
        return { colSpan: current.edit_flag ? 0 : 1 };
      },
      render: (current, value, index) =>
        current.deleted_flg === '1' ? (
          <Icons.IconEditGy className="table-cell-icon" />
        ) : (
          <Icons.IconEdit
            className="table-cell-icon tc-action-icon"
            onClick={() => {
              let newAry = structuredClone(shopLocations);
              newAry[index].edit_flag = true;
              handlerFormikFieldChange(formik, shopLocationFieldName, newAry);
            }}
          />
        ),
    },
    {
      title: '削除',
      align: 'center',
      width: '80px',
      render: (current, value, index) => (
        <Icons.IconTrash
          className="table-cell-icon tc-action-icon"
          onClick={() => {
            let newAry = [...shopLocations];
            newAry.splice(index, 1);
            handlerFormikFieldChange(formik, shopLocationFieldName, newAry);
            deleteLocationInfoList && deleteLocationInfoList(value);
          }}
        />
      ),
    },
  ];

  return (
    <div className={'map-element_area'}>
      <div className={'map-element_select-field-area'}>
        <div className="map-element_select-field">
          <span className={'map-element_select-field-label'}>配信店舗</span>
          <DualListBox
            selectBoxId="shop_select_box"
            brandList={accountBrandList}
            shopList={accountShopList}
            selectShops={selectedShopList}
            setSelectShops={v =>
              handlerFormikFieldChange(formik, selectShopListFieldName, v)
            }
            availableShops={availableShops}
            setAvailableShops={setAvailableShops}
            disabled={false}
          />
        </div>
        <TcButton
          text={'反映'}
          onClick={() => onclickDecision(0)}
          disabled={
            shopLocations?.length === 0 && selectedShopList?.length === 0
          }
        />
      </div>
      {shopLocations.length !== 0 ? (
        <div className={'map-element_area-map-area'}>
          <GeoMap center={center} makers={[center]} setCenter={setCenter} />
          <TcTable
            columns={columns}
            dataSource={shopLocations}
            pagenationFlag={false}
          />
          <div className="add-btn-area">
            <Tooltip
              title="入力枠追加"
              color="#C4A6A9"
              overlayClassName="add-tooltip"
            >
              <Icons.IconPlusWh
                className="plus-icon"
                onClick={() => {
                  let newAry = [...shopLocations];
                  newAry.push(initialLocation);
                  handlerFormikFieldChange(
                    formik,
                    shopLocationFieldName,
                    newAry
                  );
                }}
              />
            </Tooltip>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MapElement;
