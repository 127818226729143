import React from 'react';
import classNames from 'classnames';
import { Modal } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  condOptions,
  flagOptions,
  genderOptions2,
  prefectures,
} from '../../constants/options';
import { contentsActions } from '../../slices/contentsSlice';
import ModalTitle from '../modalTitle';
import Button from '../../components/tcButton';
import ImagesReference from '../imagesReference';
import { toSlateState } from '../richTextEditor/utils/serializer';
import RichTextEditor from '../richTextEditor';
import './style.scss';
import VideoImageTagElement from '../videoImageTagElement';
import { convertImageFieldName } from '../../utils/fnUtil';

/* コンテンツ参照モーダル */

const ContentsReferenceModal = () => {
  const dispatch = useDispatch();

  const {
    accountInfo: {
      new_contents_flag,
      contents_condition_flag,
      add_user_data,
      add_article_data: { video_upload_flag },
    },
    accountShopList,
  } = useSelector(state => state.account);

  const { referenceModalVisible, referenceContents } = useSelector(
    state => state.contents
  );

  const { selectOptionList } = useSelector(state => state.name);

  const initialValues = {
    start_post_period_date: null,
    start_post_period_hour: null,
    start_post_period_minutes: null,
    end_post_period_date: null,
    end_post_period_hour: null,
    end_post_period_minutes: null,
    display_order: null,
    contents_type: '1',
    title: null,
    sub_title: null,
    slider: '0',
    notice_flag: '0',
    link: null,
    banner_image_url: null,
    text: '',
    image_url: Array(9).fill(''),
  };

  const {
    start_post_period,
    end_post_period,
    display_order,
    title,
    sub_title,
    slider,
    notice_flag,
    link,
    banner_image_url,
    text,
    rich_text,
    image_url,
    contents_type,
    cond_flag,
    list_type,
    upload_file_name,
    from_age,
    to_age,
    gender,
    birth_month,
    prefectures_list,
    shop_list,
    add_user_data_params,
  } = referenceContents || initialValues;

  const onBack = () => {
    dispatch(contentsActions.closeReferenceModal());
  };

  const dateToString = (date, format) => moment(date)?.format(format) || '';
  const rangeDateString = (from, to) => {
    const fromString = dateToString(from, 'YYYY/M/D HH:mm');
    const toString = dateToString(to, 'YYYY/M/D HH:mm');
    return `${fromString} ~ ${toString}`;
  };

  const displaySelectOptionLabel = type => {
    return (
      selectOptionList
        ?.find(s => s.field_name === 'コンテンツ種別')
        ?.select_option.find(o => o.value === type)?.label ?? '-'
    );
  };

  return (
    <Modal
      className={'contents-modal tc-modal'}
      open={referenceModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={900}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content reference-content-modal-area">
        <ModalTitle title={'コンテンツ参照'} />
        <div className="input-area">
          <div className="reference-area">
            <span className={'pre-label'}>掲載期間</span>
            <span className="font-sm">
              {rangeDateString(start_post_period, end_post_period)}
            </span>
          </div>
          <div className="reference-area">
            <span className={'pre-label'}>表示優先順</span>
            <span className="font-sm">{display_order ?? '-'} </span>
          </div>
          <div className="reference-area">
            <span className={'pre-label'}>コンテンツ種別</span>
            <span className="font-sm">
              {displaySelectOptionLabel(contents_type)}
            </span>
          </div>
          <div className="reference-area">
            <span className={'pre-label'}>タイトル</span>
            <span className="font-sm">{title} </span>
          </div>
          <div className="reference-area">
            <span className={'pre-label'}>サブタイトル</span>
            <span className="font-sm">{sub_title} </span>
          </div>
          <div className="check-area">
            <span className={'pre-label'}>スライダー</span>
            <span className="font-sm">
              {flagOptions?.filter(e => e.value === slider)?.[0]?.label}
            </span>
          </div>
          <div className="check-area">
            <span className={'pre-label'}>プッシュ通知配信</span>
            <span className="font-sm">
              {flagOptions?.filter(e => e.value === notice_flag)?.[0]?.label}
            </span>
          </div>
          <div className="check-area">
            <span className={'pre-label'}>配信条件</span>
            <span className="font-sm">
              {condOptions?.filter(e => e.value === cond_flag)?.[0]?.label}
            </span>
          </div>
          {contents_condition_flag === 1 && (
            <>
              {cond_flag === '1' ? (
                <>
                  <div className="reference-area">
                    <span className={'pre-label'}>年齢</span>
                    <span className="font-sm">
                      {!from_age && !to_age
                        ? '指定なし'
                        : `${from_age ?? '指定なし'} ~ ${to_age ?? '指定なし'}`}
                    </span>
                  </div>
                  <div className="reference-area">
                    <span className={'pre-label'}>性別</span>
                    <span className="font-sm">
                      {genderOptions2[gender]?.label}
                    </span>
                  </div>
                  <div className="reference-area">
                    <span className={'pre-label'}>誕生月</span>
                    <span className="font-sm">
                      {birth_month ? `${birth_month} 月` : '指定なし'}
                    </span>
                  </div>
                  <div className="check-area">
                    <span className={'pre-label'} style={{ flexShrink: 0 }}>
                      都道府県
                    </span>
                    <div className="font-sm" style={{ whiteSpace: 'pre-wrap' }}>
                      {prefectures_list
                        ?.map(
                          e => prefectures?.find(p => p?.value === e)?.label
                        )
                        ?.join(', ') || '指定なし'}
                    </div>
                  </div>
                  <div className="check-area">
                    <span className={'pre-label'} style={{ flexShrink: 0 }}>
                      お気に入り店舗
                    </span>
                    <div className="font-sm" style={{ whiteSpace: 'pre-wrap' }}>
                      {shop_list
                        ?.map(
                          e =>
                            accountShopList?.filter(
                              s => e === s?.shop_code
                            )?.[0]?.shop_name || e
                        )
                        ?.join(', ') || '指定なし'}
                    </div>
                  </div>
                </>
              ) : (
                cond_flag === '2' && (
                  <div className="reference-area">
                    <span className={'pre-label'}>IDリスト</span>
                    <div
                      style={{
                        flexFlow: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div
                        className="font-sm"
                        style={{ marginBottom: 10 }}
                      >{`ファイル区分：${
                        list_type === '0' ? '端末ID' : '会員番号'
                      }`}</div>
                      <div className="font-sm">{`ファイル名：${
                        upload_file_name ? upload_file_name : '-'
                      }`}</div>
                    </div>
                  </div>
                )
              )}
            </>
          )}
          {add_user_data
            ?.filter(a =>
              Object.keys(add_user_data_params ?? {})?.includes(a.field_name)
            )
            ?.map(a => {
              const paramValue = add_user_data_params?.[a.field_name]; // add_user_data_paramsの該当フィールドの値（配列）

              let displayValue = '指定なし';
              if (a.filter_type === '2' && Array.isArray(paramValue)) {
                // filter_type=2の場合 → paramValueの各コードをfilter_listから探してlist_nameに変換
                const listNames = paramValue.map(code => {
                  const match = a.filter_list?.find(
                    item => item.list_code === code
                  );
                  return match ? match.list_name : code; // 一致しない場合はそのまま数値
                });
                displayValue = listNames.join(', ');
              } else if (paramValue) {
                // 通常の表示
                displayValue = paramValue;
              }

              return (
                <div className="check-area" key={a.field_name}>
                  <span className="pre-label">{a.name}</span>
                  <div className="font-sm" style={{ whiteSpace: 'pre-wrap' }}>
                    {displayValue}
                  </div>
                </div>
              );
            })}

          <div className="reference-area">
            <span className={'pre-label'}>外部リンク</span>
            <span className="font-sm">{link} </span>
          </div>
          <div className="image-area">
            <span className={'pre-label'}>
              {convertImageFieldName(video_upload_flag, 'バナー画像')}
            </span>
            <div className="image-border">
              <VideoImageTagElement imageUrl={banner_image_url} />
            </div>
          </div>

          {new_contents_flag === 0 ? (
            <>
              <div
                className="reference-area"
                style={{ alignItems: 'flex-start' }}
              >
                <span className={'pre-label'} style={{ flexShrink: 0 }}>
                  本文
                </span>
                <div
                  className="font-sm"
                  style={{
                    width: 'calc(100% - 130px)',
                    boxSizing: 'border-box',
                    wordWrap: 'break-word',
                  }}
                >
                  {text}
                </div>
              </div>
              <div className="image-area">
                <label className={'pre-label'} style={{ flexShrink: 0 }}>
                  画像
                </label>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '80%',
                  }}
                >
                  <ImagesReference
                    image_url={image_url}
                    from={0}
                    to={2}
                    width={180}
                    height={200}
                  />
                  <ImagesReference
                    image_url={image_url}
                    from={3}
                    to={5}
                    width={180}
                    height={200}
                  />
                  <ImagesReference
                    image_url={image_url}
                    from={6}
                    to={8}
                    width={180}
                    height={200}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className={classNames({ 'detail-area': true })}>
              <div className="text-area">
                <label className="pre-label">本文</label>
                <div className="text-area" style={{ flexGrow: 1 }}>
                  <span className="editor-detail">
                    <RichTextEditor
                      readOnly={true}
                      editorState={toSlateState(rich_text)}
                    />
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className="button-container">
            <Button
              text="閉じる"
              theme="white"
              onClick={() => dispatch(contentsActions.closeReferenceModal())}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContentsReferenceModal;
