import React, { useEffect, useRef } from 'react';
import PageTitle from '../../components/pageTitle';
import Icons from '../../constants/Icons';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import TcButton from '../../components/tcButton';
import { dateFormat, handlerFormikFieldChange } from '../../utils/fnUtil';
import ColumnTitle from '../../components/columnTitle';
import TcTable from '../../components/tcTable';
import TableTitle from '../../components/tableTitle';
import './style.scss';
import { useUpdateEffect } from 'react-use';
import SelectColumsModal from '../../components/modal/selectCloumsModal';
import {
  fetchStampRallyUserResultCsvDownload,
  fetchStampRallyUserResultCsvFavoriteItem,
  fetchStampRallyUserResultCsvFavoriteItemCreate,
  stampRallyUserResultActions,
  fetchStampUserResultList,
} from '../../slices/stampRallyUserResultSlice';
import { stampRallyUserResultColumns } from '../../constants/columns';
import CsvDownloadModal from '../../components/modal/csvDownloadModal';
import {
  csvDownloadTypes,
  csvFavoriteItemTypes,
  stampDateTypeOptions,
  stampRallyUserResultFavoriteItemOptions,
} from '../../constants/options';
import InputContainer from '../../components/inputContainer';
import TcRadio from '../../components/tcCheckbox/tcRadio';
import moment from 'moment';
import TextBox from '../../components/util/textBox';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';
import Yup from "../../utils/yupUtil";

const StampRallyUserResult = () => {
  const dispatch = useDispatch();

  const parent = useRef(null);

  const {
    searchParams,
    total,
    csvModalVisible,
    selectColumsModalVisible,
    favoriteItem,
    stampRallyUserResultList,
    showFields,
  } = useSelector(state => state.stampRallyUserResult);

  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: values =>
      dispatch(stampRallyUserResultActions.saveSearchParams(values)),
  });

  const yupObject = {
    csv_from_date: Yup.string().nullable().required(),
    csv_to_date: Yup.string().nullable().required(),
  };

  const csvFormik = useFormik({
    initialValues: {
      csv_date_type: '1',
      csv_from_date: null,
      csv_to_date: null,
      checkedList: [],
    },
    validationSchema: Yup.object(yupObject),
    onSubmit: values => {
      const { csv_date_type, csv_from_date, csv_to_date, checkedList } = values;

      dispatch(
        fetchStampRallyUserResultCsvDownload({
          screen_type: csvDownloadTypes['stamp_rally_user_result'],
          [{
            0: 'from_create_at',
            1: 'from_expire_date',
          }[csv_date_type]]: dateFormat(csv_from_date, 'YYYY/MM/DD'),

          [{
            0: 'to_create_at',
            1: 'to_expire_date',
          }[csv_date_type]]: dateFormat(csv_to_date, 'YYYY/MM/DD'),
          columns: checkedList,
        })
      );
    },
  });

  useUpdateEffect(
    () => dispatch(fetchStampUserResultList(searchParams)),
    [searchParams]
  );

  const displayColumnOptions = [
    ...stampRallyUserResultColumns.map(v => ({
      label: v.title,
      value: v.sortField,
    })),
  ];

  const columns = [
    ...stampRallyUserResultColumns
      .filter(column => showFields.some(field => field === column.sortField))
      .map(col => ({
        key: col.sortField,
        title: (
          <ColumnTitle
            title={col.title}
            formik={formik}
            sortField={col.sortField}
            onClick={sort => {
              dispatch(stampRallyUserResultActions.saveSearchParams({ sort }));
            }}
          />
        ),
        dataIndex: col.sortField,
        render: col.render,
      })),
  ];

  const { csv_from_date, csv_to_date } = csvFormik.values;

  useEffect(() => {
    dispatch(stampRallyUserResultActions.saveSearchParams(null));
    dispatch(
      fetchStampRallyUserResultCsvFavoriteItem({
        screen_type: csvFavoriteItemTypes['stamp_rally_user_result'],
      })
    );
    dispatch(
      stampRallyUserResultActions.saveShowFields(
        stampRallyUserResultColumns
          .filter(v => !v.defaultHiddenFiled)
          .map(v => v?.sortField)
      )
    );
    return () => {
      dispatch(stampRallyUserResultActions.clear());
    };
  }, [dispatch]);

  return (
    <div
      className="content-body management-page stamp-rally-user-result"
      ref={parent}
    >
      <PageTitle
        title="ユーザー×台紙単位実績一覧"
        subTitle={[
          '発行済みの最新台紙情報が表示されます。最新以外の情報は押印実績明細一覧からご確認ください。',
        ]}
        subTitleClass="subtitle-grey"
        icon={<Icons.IconStampRally />}
        isStampRally
      />
      <div className="search-form">
        <InputContainer parent={parent} max={1098}>
          <TextBox formik={formik} label="会員番号" fieldName="user_code" />
          <TextBox
            formik={formik}
            label="台紙CD"
            fieldName="stamp_rally_code"
          />
          <TextBox
            formik={formik}
            label="台紙名"
            fieldName="stamp_rally_name"
          />
          <DateTimeRangePicker
            formik={formik}
            label="最新台紙失効日"
            fromFieldName="from_expire_date"
            toFieldName="to_expire_date"
          />
          <DateTimeRangePicker
            formik={formik}
            label="最新台紙初回押印日"
            fromFieldName="from_first_stamp_rally_date"
            toFieldName="to_first_stamp_rally_date"
          />
          <DateTimeRangePicker
            formik={formik}
            label="最新台紙最終押印日"
            fromFieldName="from_last_stamp_rally_date"
            toFieldName="to_last_stamp_rally_date"
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
        </div>
      </div>

      <TableTitle
        searchCount={total}
        onClickDisplayChange={() =>
          dispatch(stampRallyUserResultActions.openSelectColumsModal())
        }
        onClickCsvOutput={() => {
          handlerFormikFieldChange(
            csvFormik,
            'csv_from_date',
            csv_from_date || moment().format('YYYY/MM/01')
          );
          handlerFormikFieldChange(
            csvFormik,
            'csv_to_date',
            csv_to_date || moment().format('YYYY/MM/DD')
          );
          dispatch(stampRallyUserResultActions.openCsvDownloadModal());
        }}
      />
      <TcTable
        columns={columns}
        dataSource={stampRallyUserResultList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(stampRallyUserResultActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />

      <CsvDownloadModal
        line={5}
        modalVisible={csvModalVisible}
        requiredText={null}
        options={stampRallyUserResultFavoriteItemOptions}
        favoriteItem={favoriteItem}
        width={1000}
        searchArea={
          <>
            <div className="csv-modal-date-container">
              <TcRadio
                formik={csvFormik}
                isRadio={true}
                fieldName="csv_date_type"
                required={true}
                options={stampDateTypeOptions}
                width={240}
                onChange={v =>
                  handlerFormikFieldChange(csvFormik, 'csv_date_type', v)
                }
              />
              <DateTimeRangePicker
                formik={csvFormik}
                fromFieldName="csv_from_date"
                toFieldName="csv_to_date"
                hasLabel={false}
                style={{ marginLeft: 24 }}
              />
            </div>
          </>
        }
        onFavoriteItem={checkedList => {
          const initial = stampRallyUserResultFavoriteItemOptions.reduce(
            (acc, item) => ({
              ...acc,
              favorite: {
                ...acc.favorite,
                [item.value]: '0',
              },
            }),
            {
              screen_type: csvFavoriteItemTypes['stamp_rally_user_result'],
              favorite: {},
            }
          );
          const param = checkedList.reduce(
            (acc, item) => ({
              ...acc,
              favorite: {
                ...acc.favorite,
                [item]: '1',
              },
            }),
            initial
          );
          dispatch(fetchStampRallyUserResultCsvFavoriteItemCreate(param));
        }}
        onClose={() =>
          dispatch(stampRallyUserResultActions.closeCsvDownloadModal())
        }
        onDownload={checkedList => {
          csvFormik.setValues({
            ...csvFormik.values,
            checkedList: checkedList,
          });
          csvFormik.handleSubmit();
        }}
        disabled={!csvFormik.isValid}
      />

      <SelectColumsModal
        modalTitle="表示切替"
        title="表示する項目を選択してください"
        modalVisible={selectColumsModalVisible}
        closeModal={() =>
          dispatch(stampRallyUserResultActions.closeSelectColumsModal())
        }
        options={displayColumnOptions}
        onDisplay={checkedList =>
          dispatch(stampRallyUserResultActions.saveShowFields(checkedList))
        }
        chekedValues={stampRallyUserResultColumns
          .filter(v => !v.defaultHiddenFiled)
          .map(v => v?.sortField)}
        initialize={false}
      />
    </div>
  );
};

export default StampRallyUserResult;
