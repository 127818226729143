import axios from '../utils/axiosUtil';
import moment from 'moment';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { globalActions } from './globalSlice';
import { downloadFile } from '../utils/fnUtil';
import qs from 'qs';

const initialSearchParams = {
  device_id: '',
  user_code: '',
  from_birthday: null,
  to_birthday: null,
  prefectures: null,
  gender: null,
  favorite_shop: null,
  from_download_day: null,
  to_download_day: null,
  from_update_day: null,
  to_update_day: null,
  offset: 0,
  count: 10,
  sort: 'device_id_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  userList: [],
  total: 0,
  total_count: null,
  csvDownloadModalVisible: false,
  favoriteItem: [],
  showFields: [],
  selectColumnsVisible: false,
};

export const fetchUserList = createAsyncThunk(
  'fetchUserList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('/user/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));
    return result;
  }
);

export const fetchUserCsvFavoriteItem = createAsyncThunk(
  'fetchUserCsvFavoriteItem',
  async () =>
    axios.get('csv/favorite_item/list', { params: { screen_type: '4' } })
);

export const updateUserCsvFavoriteItem = createAsyncThunk(
  'updateUserCsvFavoriteItem',
  async (params, { dispatch }) => {
    const updateFavoriteRes = await axios.post(
      'csv/favorite_item/create',
      params
    );
    updateFavoriteRes?.code === '0' &&
      dispatch(globalActions.showSingleModal('お気に入り登録が完了しました'));
    return params.favorite;
  }
);

export const downloadUser = createAsyncThunk(
  'downloadUser',
  async (params, { dispatch }) => {
    const result = await axios.get('csv/download', {
      params,
      paramsSerializer: params => qs.stringify(params, { allowDots: true }),
    });
    if (!!!result) {
      dispatch(globalActions.showCommonErrorModal());
    }
    return result;
  }
);

export const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams['offset'] = payload;
    },
    openCsvDownloadModal: state => ({
      ...state,
      csvDownloadModalVisible: true,
    }),
    closeCsvDownloadModal: state => ({
      ...state,
      csvDownloadModalVisible: false,
    }),
    saveShowFields: (state, { payload }) => ({
      ...state,
      selectColumnsVisible: false,
      showFields: payload,
    }),
    openSelectColumnsModal: state => ({
      ...state,
      selectColumnsVisible: true,
    }),
    closeSelectColumnsModal: state => ({
      ...state,
      selectColumnsVisible: false,
    }),
    reset: () => initialState,
  },
  extraReducers: {
    [fetchUserList.pending]: (state) => {
      // fetchUserListが呼ばれたときに、userListをクリア
      state.userList = [];
    },
    [fetchUserList.fulfilled]: (state, { payload }) => {
      state.userList = payload.item ?? [];
      state.total_count = payload.total_count;
      state.total = payload.search_count;
    },
    [fetchUserCsvFavoriteItem.fulfilled]: (state, { payload }) => {
      state.favoriteItem = payload?.item?.favorite || {};
    },
    [updateUserCsvFavoriteItem.fulfilled]: (state, { payload }) => {
      state.favoriteItem = payload;
    },
    [downloadUser.fulfilled]: (_, { payload }) => {
      if (payload && typeof payload === 'string') {
        downloadFile(
          payload,
          `ユーザー管理一覧_${moment().format('YYYYMMDDHHmmss')}.csv`
        );
      }
    },
  },
});

export const userManagementActions = userManagementSlice.actions;
export default userManagementSlice.reducer;
