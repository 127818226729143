import React, { useEffect, useRef } from 'react';
import PageTitle from '../../components/pageTitle';
import Icons from '../../constants/Icons';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import TcButton from '../../components/tcButton';
import { dateFormat, handlerFormikFieldChange } from '../../utils/fnUtil';
import ColumnTitle from '../../components/columnTitle';
import TcTable from '../../components/tcTable';
import TableTitle from '../../components/tableTitle';
import './style.scss';
import {
  csvDownloadTypes,
  csvFavoriteItemTypes,
  pointExchangeResultFavoriteItemOptions,
} from '../../constants/options';
import {
  fetchPointExchangeCsvDownload,
  pointExchangeResultActions,
  fetchPointExchangeResultList,
  fetchPointExchangeCsvFavoriteItem,
  fetchPointExchangeCsvFavoriteItemCreate,
} from '../../slices/pointExchangeResultSlice';
import { useUpdateEffect } from 'react-use';
import { pointExchangeColumns } from '../../constants/columns';
import CsvDownloadModal from '../../components/modal/csvDownloadModal';
import moment from 'moment';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';
import Yup from "../../utils/yupUtil";

const PointExchangeResult = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const {
    searchParams,
    total,
    csvModalVisible,
    favoriteItem,
    pointExchangeResultList,
  } = useSelector(state => state.pointExchangeResult);

  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: values =>
      dispatch(pointExchangeResultActions.saveSearchParams(values)),
  });

  const yupObject = {
    from_create_at: Yup.string().nullable().required(),
    to_create_at: Yup.string().nullable().required(),
  };

  const csvFormik = useFormik({
    initialValues: {
      from_create_at: null,
      to_create_at: null,
      checkedList: [],
    },
    validationSchema: Yup.object(yupObject),
    onSubmit: values => {
      const { from_create_at, to_create_at, checkedList } = values;

      dispatch(
        fetchPointExchangeCsvDownload({
          from_create_at: dateFormat(from_create_at, 'YYYY/MM/DD'),
          to_create_at: dateFormat(to_create_at, 'YYYY/MM/DD'),
          screen_type: csvDownloadTypes['point_exchange_result'],
          columns: checkedList,
        })
      );
    },
  });

  const columns = [
    ...pointExchangeColumns.map(col => ({
      key: col.sortField,
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort => {
            dispatch(pointExchangeResultActions.saveSearchParams({ sort }));
          }}
        />
      ),
      dataIndex: col.sortField,
      render: col.render,
    })),
  ];

  const { csv_from_date, csv_to_date } = csvFormik.values;

  useEffect(() => {
    dispatch(pointExchangeResultActions.saveSearchParams(null));
    dispatch(
      fetchPointExchangeCsvFavoriteItem({
        screen_type: csvFavoriteItemTypes['point_exchange_result'],
      })
    );
    return () => dispatch(pointExchangeResultActions.clear());
  }, [dispatch]);

  useUpdateEffect(
    () => dispatch(fetchPointExchangeResultList(searchParams)),
    [searchParams]
  );

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle title="ポイント交換実績一覧" icon={<Icons.IconStampRally />} />
      <div className="search-form">
        <InputContainer parent={parent} max={724}>
          <TextBox formik={formik} label="会員番号" fieldName="user_code" />
          <TextBox
            formik={formik}
            label="台紙CD"
            fieldName="stamp_rally_code"
          />
          <TextBox
            formik={formik}
            label="台紙名"
            fieldName="stamp_rally_name"
          />
          <DateTimeRangePicker
            formik={formik}
            label="交換日"
            fromFieldName="exchange_start_date"
            toFieldName="exchange_end_date"
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
        </div>
      </div>

      <TableTitle
        searchCount={total}
        onClickCsvOutput={() => {
          handlerFormikFieldChange(
            csvFormik,
            'csv_from_date',
            csv_from_date || moment().format('YYYY/M/1')
          );
          handlerFormikFieldChange(
            csvFormik,
            'csv_to_date',
            csv_to_date || moment().format('YYYY/M/D')
          );
          dispatch(pointExchangeResultActions.openCsvDownloadModal());
        }}
      />

      <TcTable
        columns={columns}
        dataSource={pointExchangeResultList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(pointExchangeResultActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />

      <CsvDownloadModal
        line={5}
        modalVisible={csvModalVisible}
        requiredText={null}
        options={pointExchangeResultFavoriteItemOptions}
        favoriteItem={favoriteItem}
        width={1000}
        disabled={!csvFormik.isValid}
        searchArea={
          <DateTimeRangePicker
            label={'交換日'}
            formik={csvFormik}
            fromFieldName={'from_create_at'}
            toFieldName={'to_create_at'}
            isRequired
          />
        }
        onFavoriteItem={checkedList => {
          const initial = pointExchangeResultFavoriteItemOptions.reduce(
            (acc, item) => ({
              ...acc,
              favorite: {
                ...acc.favorite,
                [item.value]: '0',
              },
            }),
            {
              screen_type: csvFavoriteItemTypes['point_exchange_result'],
              favorite: {},
            }
          );
          const param = checkedList.reduce(
            (acc, item) => ({
              ...acc,
              favorite: {
                ...acc.favorite,
                [item]: '1',
              },
            }),
            initial
          );
          dispatch(fetchPointExchangeCsvFavoriteItemCreate(param));
        }}
        onClose={() =>
          dispatch(pointExchangeResultActions.closeCsvDownloadModal())
        }
        onDownload={checkedList => {
          csvFormik.setValues({
            ...csvFormik.values,
            checkedList: checkedList,
          });
          csvFormik.handleSubmit();
        }}
      />
    </div>
  );
};

export default PointExchangeResult;
